.blogsHero__picture {
	padding: 4.4rem 4.1rem 7rem 4.1rem;
}

.blogs__container {
	display: flex;
	flex-direction: column;
	gap: 4.8rem;
	padding: 4rem 1.6rem 6.1rem 1.6rem;
}

.blog__card {
	border-radius: 50px;
	box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25);
	width: 100%;
	font-family: "Akzidenz-Grotesk Pro Ext";
	font-size: 1.4rem;
	line-height: 3.2rem;
}

.blogs__head {
	height: 13rem;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50px 50px 0px 0px;
}

.blogs__body {
	padding: 2.5rem 1.9rem 2.4rem 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
}

.blogs__head--marketing {
	background-image: url("../../../assets/images/blogs/marketing-digital.png?as=webp&width=784");
	background-position: center -3rem;
}

.blogs__head--diseno {
	background-image: url("../../../assets/images/blogs/diseno-grafico.png?as=webp&width=784");
	background-position: center -4rem;
}

.blogs__head--sitios {
	background-image: url("../../../assets/images/blogs/sitios-web.png?as=webp&width=784");
	background-position: center -4rem;
}

.blogs__head--aplicaciones {
	background-image: url("../../../assets/images/blogs/aplicaciones-web.png?as=webp&width=784");
	background-position: center -4rem;
}

.blogs__description {
	width: 34.2rem;
	line-height: 1.6rem;
}

.blogs__link {
	font-size: 1.4rem;
	color: var(--white-web-space);
	background-color: var(--violet-dark-web-space);
	max-width: 14.4rem;
	text-align: center;
	border-radius: 14px;
	padding: 0.9rem 1.9rem;
	align-self: end;
	text-decoration: none;
	font-family: "Akzidenz-Grotesk Pro Med Ext";
	line-height: 1.4rem;
	font-size: 0.8rem;
}

.blog {
	padding-bottom: 8.2rem;
}

.blog h1 {
	font-size: 2.4rem;
	line-height: 2.4rem;
	font-family: "Akzidenz-Grotesk Pro Med";
	color: var(--violet-dark-web-space);
	font-size: 2.4rem;
	padding-top: 1.6rem;
	padding-bottom: 4.8rem;
}

.blog h2 {
	font-family: "Akzidenz-Grotesk Pro";
	color: var(--violet-dark-web-space);
	font-weight: normal;
	font-size: 1.6rem;
}

.blog__body {
	display: flex;
	flex-direction: column;
	gap: 2.4rem;
	line-height: 2.4rem;
}

.blog h3 {
	font-family: "Akzidenz-Grotesk Pro Med";
	color: var(--violet-dark-web-space);
	font-size: 2.2rem;
}

.blog p {
	font-family: "Akzidenz-Grotesk Pro Light";
	font-weight: 300;
	font-size: 2rem;
}

.blog__main {
	display: flex;
	flex-direction: column;
	padding-left: 1.6rem;
	padding-right: 1.6rem;
}

.blog__picture {
	margin-top: 3.5rem;
	margin-bottom: 2.8rem;
	border-radius: 40px;
	overflow: hidden;
	display: block;
}

@media (min-width: 1024) {
	.blogs__head--marketing {
		background-image: url("../../../assets/images/blogs/marketing-digital.png?as=webp&width=784");
		background-position: center -12rem;
	}

	.blogs__head--diseno {
		background-image: url("../../../assets/images/blogs/diseno-grafico.png?as=webp&width=784");
		background-position: center -5rem;
	}

	.blogs__head--sitios {
		background-image: url("../../../assets/images/blogs/sitios-web.png?as=webp&width=784");
		background-position: center -12rem;
	}

	.blogs__head--aplicaciones {
		background-image: url("../../../assets/images/blogs/aplicaciones-web.png?as=webp&width=784");
		background-position: center -12rem;
	}
	.blog__hero {
		transform: translateY(-17%);
	}

	.blogs__head {
		height: 20rem;
	}
	.blog__picture {
		height: 60.8rem;
		display: block;
		margin-top: 11.6rem;
		margin-bottom: 7.9rem;
	}

	.blog__card {
		font-size: 2.4rem;
	}

	.blogs__container {
		display: grid;
		grid-template-columns: 1fr 1fr;
		row-gap: 7.2rem;
		column-gap: 3.2rem;
		padding: 15.1rem 16rem 0 16rem;
	}

	.blogs__body {
		padding: 3.2rem 4rem 4rem 3.2rem;
		gap: 0;
	}

	.blogs__link {
		font-size: 1.4rem;
	}

	.blogs__description {
		width: 47.2rem;
		line-height: 3.2rem;
	}

	.blog__main {
		padding-left: 16rem;
		padding-right: 16rem;
	}

	.blog p {
		font-size: 3.6rem;
		line-height: 5rem;
	}

	.blog h1 {
		font-size: 7rem;
		line-height: 7.2rem;
		padding-top: 1.8rem;
		padding-bottom: 9.8rem;
	}

	.blog h2 {
		font-size: 3rem;
		line-height: 3rem;
	}

	.blog h3 {
		font-size: 3.6rem;
	}

	.blog__body {
		gap: 4rem;
	}

	.blogsHero__picture {
		padding: 4.4rem 16rem 7rem 4.1rem;
	}
}
