.blogsHero__picture {
  padding: 4.4rem 4.1rem 7rem;
}

.blogs__container {
  flex-direction: column;
  gap: 4.8rem;
  padding: 4rem 1.6rem 6.1rem;
  display: flex;
}

.blog__card {
  border-radius: 50px;
  width: 100%;
  font-family: Akzidenz-Grotesk Pro Ext;
  font-size: 1.4rem;
  line-height: 3.2rem;
  box-shadow: 4px 4px 4px #00000040;
}

.blogs__head {
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50px 50px 0 0;
  height: 13rem;
}

.blogs__body {
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.5rem 1.9rem 2.4rem 1.5rem;
  display: flex;
}

.blogs__head--marketing {
  background-image: url("marketing-digital.7692bffd.webp");
  background-position: 50% -3rem;
}

.blogs__head--diseno {
  background-image: url("diseno-grafico.d78a3e6d.webp");
  background-position: 50% -4rem;
}

.blogs__head--sitios {
  background-image: url("sitios-web.b32eac70.webp");
  background-position: 50% -4rem;
}

.blogs__head--aplicaciones {
  background-image: url("aplicaciones-web.067465de.webp");
  background-position: 50% -4rem;
}

.blogs__description {
  width: 34.2rem;
  line-height: 1.6rem;
}

.blogs__link {
  color: var(--white-web-space);
  background-color: var(--violet-dark-web-space);
  text-align: center;
  border-radius: 14px;
  align-self: end;
  max-width: 14.4rem;
  padding: .9rem 1.9rem;
  font-family: Akzidenz-Grotesk Pro Med Ext;
  font-size: .8rem;
  line-height: 1.4rem;
  text-decoration: none;
}

.blog {
  padding-bottom: 8.2rem;
}

.blog h1 {
  color: var(--violet-dark-web-space);
  padding-top: 1.6rem;
  padding-bottom: 4.8rem;
  font-family: Akzidenz-Grotesk Pro Med;
  font-size: 2.4rem;
  line-height: 2.4rem;
}

.blog h2 {
  color: var(--violet-dark-web-space);
  font-family: Akzidenz-Grotesk Pro;
  font-size: 1.6rem;
  font-weight: normal;
}

.blog__body {
  flex-direction: column;
  gap: 2.4rem;
  line-height: 2.4rem;
  display: flex;
}

.blog h3 {
  color: var(--violet-dark-web-space);
  font-family: Akzidenz-Grotesk Pro Med;
  font-size: 2.2rem;
}

.blog p {
  font-family: Akzidenz-Grotesk Pro Light;
  font-size: 2rem;
  font-weight: 300;
}

.blog__main {
  flex-direction: column;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  display: flex;
}

.blog__picture {
  border-radius: 40px;
  margin-top: 3.5rem;
  margin-bottom: 2.8rem;
  display: block;
  overflow: hidden;
}

@media (width >= 1024px) {
  .blogs__head--marketing {
    background-image: url("marketing-digital.7692bffd.webp");
    background-position: 50% -12rem;
  }

  .blogs__head--diseno {
    background-image: url("diseno-grafico.d78a3e6d.webp");
    background-position: 50% -5rem;
  }

  .blogs__head--sitios {
    background-image: url("sitios-web.b32eac70.webp");
    background-position: 50% -12rem;
  }

  .blogs__head--aplicaciones {
    background-image: url("aplicaciones-web.067465de.webp");
    background-position: 50% -12rem;
  }

  .blog__hero {
    transform: translateY(-17%);
  }

  .blogs__head {
    height: 20rem;
  }

  .blog__picture {
    height: 60.8rem;
    margin-top: 11.6rem;
    margin-bottom: 7.9rem;
    display: block;
  }

  .blog__card {
    font-size: 2.4rem;
  }

  .blogs__container {
    grid-template-columns: 1fr 1fr;
    gap: 7.2rem 3.2rem;
    padding: 15.1rem 16rem 0;
    display: grid;
  }

  .blogs__body {
    gap: 0;
    padding: 3.2rem 4rem 4rem 3.2rem;
  }

  .blogs__link {
    font-size: 1.4rem;
  }

  .blogs__description {
    width: 47.2rem;
    line-height: 3.2rem;
  }

  .blog__main {
    padding-left: 16rem;
    padding-right: 16rem;
  }

  .blog p {
    font-size: 3.6rem;
    line-height: 5rem;
  }

  .blog h1 {
    padding-top: 1.8rem;
    padding-bottom: 9.8rem;
    font-size: 7rem;
    line-height: 7.2rem;
  }

  .blog h2 {
    font-size: 3rem;
    line-height: 3rem;
  }

  .blog h3 {
    font-size: 3.6rem;
  }

  .blog__body {
    gap: 4rem;
  }

  .blogsHero__picture {
    padding: 4.4rem 16rem 7rem 4.1rem;
  }
}
/*# sourceMappingURL=index.8bb61b19.css.map */
